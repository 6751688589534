export const variables = {
    // Local
    // frontend: 'http://localhost:3001/',
    // backend: 'http://localhost:3000/'
    
    // Desarrollo
    // frontend: 'https://prepagado.kia.com.co/',
    // backend: 'https://backendprepagado.kia.com.co/'

    //Produccion
    frontend: 'https://prepagado.kia.com.co/',
    backend: 'https://backendprepagado.kia.com.co/'
}