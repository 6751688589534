import {clienteAxios} from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearSolicitudes = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/consultaSolicitudes', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: 'Ha ocurrido un problema',
                noInternet: true
            }
        })
    }
}

export const actualizarSolicitud = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
        
    }
}

export const consultaSolicitudesId = async(payload) => {
    usuarioAutenticado()
    try{
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/consultaId', payload)
        return(respuesta)
    } catch(error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaSolicitudRedencion = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/consultaSolicitudRedencion', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            } 
        })
    }
}

export const consultaSolicitudes = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/consulta', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus:false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarEstadoSolicitud = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/actualizarEstadoSolicitud', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus:false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const redemirServicio = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/redemirServicio', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus:false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaServicioARedimir = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/consulta-servicio-a-redimir', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus:false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarPaqueteSolicitud = async(payload) => {
    
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/solicitudAdquisicion/eliminarPaqueteSolicitud', payload)
        return(respuesta)
    } catch (error) {

        console.log(JSON.stringify(error))
        return({
            data: {
                estatus:false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

