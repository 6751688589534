import React, { useState, useEffect } from 'react';
import {Grid, Container, Header, Button, Table, Pagination } from 'semantic-ui-react';
import {estadoInicialFiltro} from '../../estadosIniciales/solicitudes/estadoInicial-solicitud'
import { consultaSolicitudes } from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import {Link} from 'react-router-dom'
import ItemSolicitud from '../../components/itemsListados/item-solicitud';
import ModalMensaje from '../../components/modales/modalMensaje';
import FiltroSolicitudes from '../../components/filtros/filtro.solicitudes'
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const ListaSolicitudesIC = () => {

    const [ cargando, setCargando ] = useState(true)
    const [ solicitudes, setSolicitudes ] = useState([])
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })
    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }

    useEffect(() => {
        consultar(filtro)
    }, [filtro])
    const consultar = async(filtro) => {
        const respuesta = await consultaSolicitudes(filtro)
        if(respuesta.data.estatus){
            setSolicitudes(respuesta.data.data.solicitudes)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
            setCargando(false)
        } else {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargando(false)
        }
    }

    if(cargando){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid >
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Solicitudes
                    <span>
                    <Button 
                        size="mini"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        floated='right'
                    > 
                    
                    <Link 
                        style={{textDecoration:"none", color: '#000000', fontWeight: "bold"}} 
                        to={'/nuevaSolicitud'}
                    > 
                    Nueva solicitud
                    </Link> 
                </Button>
                    </span>
                </Header>
                <FiltroSolicitudes
                    setFiltro={setFiltro}
                />
                <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                    <Table size="small" textAlign="center" celled structured singleLine unstackable selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>CREADO</Table.HeaderCell>
                                <Table.HeaderCell>ULTIMA ACTUALIZACION</Table.HeaderCell>
                                <Table.HeaderCell>NUMERO DE SOLICITUD</Table.HeaderCell>
                                <Table.HeaderCell>DISTRIBUIDOR</Table.HeaderCell>
                                <Table.HeaderCell>VIN</Table.HeaderCell>
                                <Table.HeaderCell>TIPO ID CLIENTE</Table.HeaderCell>
                                <Table.HeaderCell>ID CLIENTE</Table.HeaderCell>
                                <Table.HeaderCell>ESTADO</Table.HeaderCell>
                                <Table.HeaderCell>VER</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {solicitudes !== undefined && solicitudes.length>0 ? 
                             solicitudes.map( i => (
                                <ItemSolicitud
                                    item={i}
                                    key={i._id}
                                />
                            )) : null}
                        </Table.Body>
                    </Table>
                </Container>
                
                { solicitudes.length < 1 ? <NoItems /> : null }
                <Pagination 
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
            </Container>
            
        </Grid>
    );
}
 
export default ListaSolicitudesIC;