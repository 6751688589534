import React from 'react';
import {Table, Button} from 'semantic-ui-react'
import moment from 'moment-timezone'
import {Link} from 'react-router-dom'


const ItemSolicitud = ({
    item
}) => {

    return ( 
        <Table.Row>
            <Table.Cell> {moment(item.creado).format("DD/MM/YYYY hh:mm a")} </Table.Cell>
            <Table.Cell> {moment(item.actualizado).format("DD/MM/YYYY hh:mm a")}  </Table.Cell>
            <Table.Cell> {item.solicitud.numero}  </Table.Cell>
            <Table.Cell> {item.solicitud.concesionario} </Table.Cell>
            <Table.Cell> {item.solicitud.vin} </Table.Cell>
            <Table.Cell> {item.detallesCliente.identificacion.tipo} </Table.Cell>
            <Table.Cell> {item.detallesCliente.identificacion.numero} </Table.Cell>
            <Table.Cell> {item.estado} </Table.Cell>
            <Table.Cell> 
                <Button
                    style={{
                        padding: 10,
                        borderRadius: 0,
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                >
                    <Link
                        style={{textDecoration: 'none', color:'#000000'}}
                        to={`/detallesSolicitud/${item._id}`}
                        target="_blank"
                    >
                        Ver
                    </Link>
                </Button>
            </Table.Cell>
        </Table.Row>
    );

}
 
export default ItemSolicitud;