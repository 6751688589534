import React, {useState, useEffect} from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import {EVENTO_CARRITO_COMPRA} from '../../enum/eventos/tipo-evento';


const ModalEliminar = ({
    setCargando,
    setObjetoEliminar,
    setModalEliminar,
    modalEliminarActivo,
    eliminarPaquete,
    eliminar,
    evento,
    objetoEliminar,
    contenido
}) => {
    
    const [ estadoModal, setEstadoModal ] = useState(false)
    useEffect(() => {
        if(modalEliminarActivo){
            setEstadoModal(true)
        }
    }, [modalEliminarActivo])

    const desactivarModal = () => {
        setEstadoModal(false)
        setModalEliminar(false)
    }

    const onClickEliminar = () => {
        eliminar(objetoEliminar)
        setCargando(true)
        setModalEliminar(false)
        setObjetoEliminar('')
        setEstadoModal(false)
    }

    const EliminarPaquete = () => {
        eliminarPaquete()
        setModalEliminar(false)
        setEstadoModal(false)
    }
    return(
        <Modal
            size="tiny"
            open={estadoModal}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                {contenido.titulo}
                </Header>
            </Modal.Header>
            <Modal.Content>
                <p> {contenido.mensaje} </p>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button
                    size="tiny"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    content="Cancelar"
                    onClick={desactivarModal}
                />
                {evento === EVENTO_CARRITO_COMPRA ?
                <Button
                    size="tiny"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    primary
                    content="Eliminar"
                    onClick={EliminarPaquete}
                />
                :
                <Button
                    size="tiny"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    primary
                    content="Eliminar"
                    onClick={onClickEliminar}
                />}
            </Modal.Actions>
        </Modal>
    )
}

export default ModalEliminar;